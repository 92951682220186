import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { GetCommunityEDucationLevels } from "../../../API/GetCommunityEDucationLevel";
import { useMutation, useQuery } from "@tanstack/react-query";
import { COURSEIMG, UploadToS3 } from "../../../util/UploadToS3";
import { queryClient } from "../../../App";
import { GoPlusCircle } from "react-icons/go";
import { CiCircleRemove } from "react-icons/ci";
import Swal from "sweetalert2";
import Select from "react-select";
import { EditCourse } from "../../../API/Courses/EditCourse";
import { GetSingleCourse } from "../../../API/Courses/SingleCourse/CourseOverview/GetSingleCourse";
import { Row } from "react-bootstrap";
import { IMAGES } from "../../constant/theme";

const loginSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "First Name must be alphanumeric and can include underscores and dashes"
    ),
  subtitle: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "Last Name must be alphanumeric and can include underscores and dashes"
    ),

  language: Yup.string(),
  monthly: Yup.string(),
  price: Yup.number()
    .typeError("Price must be a number")
    .min(0, "Price cannot be negative"),
  eduLevelId: Yup.string().notOneOf([""], "Please select a education level"),
  requirements: Yup.array()
    .of(Yup.string().required("Requirements cannot be empty"))
    .min(1, "At least one Requirement is required"),
  outcomes: Yup.array()
    .of(Yup.string().required("Outcomes cannot be empty"))
    .min(1, "At least one outcome is required"),
  description: Yup.string().min(
    3,
    "description must consist of at least 3 characters "
  ),
  // .matches(
  //   /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
  //   "Last Name must be alphanumeric and can include underscores and dashes"
  // )
  // image: Yup.mixed()
  //   .test(
  //     "fileSize",
  //     "The file is too large",
  //     (value) => value && value.size <= 1024 * 1024 * 10
  //   ) // 5 MB limit
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     (value) =>
  //       value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
  //   ),
});

const languages = [
  { value: "Arabic", label: "Arabic" },
  { value: "English", label: "English" },
];
const PaymentOptions = [
  { value: false, label: "One Time" },
  { value: true, label: "Monthly Subscription" },
];

const customStyles = (error, touched) => ({
  control: (provided) => ({
    ...provided,
    borderColor:
      touched && error
        ? "red"
        : touched && !error
        ? "#7ed321"
        : provided.borderColor,
    "&:hover": {
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
    },
    boxShadow:
      touched && error
        ? "0 0 0 1px red"
        : touched
        ? "0 0 0 1px #7ed321"
        : "none",
  }),
});

const EditCourses = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState(null);
  const {
    data: CommunityLevels,
    isLoading: isCommunityLevelsLoading,
    isError: isCommunityLevelsError,
  } = useQuery({
    queryKey: ["CommunityLevels"],
    queryFn: () => GetCommunityEDucationLevels(),
  });
  const {
    data: CourseData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["CourseOverView", id],
    queryFn: () => GetSingleCourse(id),
  });

  const { mutate } = useMutation({
    mutationFn: (data) => EditCourse(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CourseOverView", id] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Course Added Successfully",
      });
      navigate(`/all-courses/${id}`);
    },
    onError: () => {
      // console.log(error.response.data);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  const LEVELS =
    !isCommunityLevelsLoading &&
    !isCommunityLevelsError &&
    CommunityLevels.map((item) => {
      return { value: item.level, label: item.level, id: item.id };
    });

  if (isLoading || isCommunityLevelsLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );

  if (isError || isCommunityLevelsError)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <span className="text-primary">Something went wrong...</span>
        </div>
      </Row>
    );

  const initialValues = {
    title: CourseData.title || "",
    subtitle: CourseData.subtitle || "",
    openDate: CourseData.openDate || "",
    language: CourseData.language || "",
    price: CourseData.price || 0,
    monthly: CourseData.monthly || "",
    eduLevelId: CourseData.eduLevel.id || "",
    requirements: CourseData.requirements || [],
    requirementsInput: "",
    outcomes: CourseData.outcomes || [],
    outcomesInput: "",
    image: CourseData.image || "",
    description: CourseData.description || "",
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    <>
      <PageTitle
        activeMenu={"All Courses"}
        secondMenu={"Edit Course"}
        motherMenu={"Courses"}
        userId={id}
        goBack={true}
        goTo={`/all-courses/${id}`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Courses Details</h4>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                const { image, ...restData } = changedValues;
                console.log(values);
                mutate(restData, {
                  onSuccess: (data) => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    if (changedValues.image) {
                      UploadToS3(
                        initialValues.image,
                        changedValues.image,
                        "Teacher/CouresImg/",
                        true
                      );
                    }
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="p-5">
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={
                                imagePreview
                                  ? imagePreview
                                  : COURSEIMG + values.image
                              }
                              alt="CourseImg"
                              onError={(e) => {
                                e.target.src = IMAGES.courseBroken;
                              }}
                            />
                            <div
                              className="upload-link"
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update">
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="update-flie"
                                // onChange={(e) => {
                                //   setFieldValue(
                                //     "image",
                                //     e.currentTarget.files[0]
                                //   );
                                onChange={(event) =>
                                  handleImageChange(event, setFieldValue)
                                }
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          <div className="author-info">
                            <span>Course ID : #{id}</span>
                          </div>
                        </div>
                        {touched.image && errors.image && (
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp">
                            {errors.image}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Course Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Coures Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="subtitle">
                            Course Subtitle
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="subtitle"
                            placeholder="Enter Course Subtitle"
                            type="text"
                            name="subtitle"
                            className={`form-control ${
                              touched.subtitle
                                ? errors.subtitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.subtitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.subtitle && errors.subtitle && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.subtitle}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.language
                              ? errors.language
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label className="form-label">
                            Language
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="language"
                            id="language"
                            placeholder="Select Language"
                            isSearchable={false}
                            options={languages}
                            styles={customStyles(
                              errors.language,
                              touched.language
                            )}
                            className={`custom-react-select ${
                              touched.language
                                ? errors.language
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={languages.find(
                              (option) => option.value === values.language
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "language",
                                option ? option.value : ""
                              );
                              setFieldTouched("language", true, false); // mark as touched but do not validate yet
                              validateField("language"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("language", true)}
                          />
                          {touched.language && errors.language && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="eduLevel"
                            className="form-label">
                            Education Level
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="eduLevelId"
                            id="eduLevelId"
                            placeholder="Select Education Level"
                            isSearchable={false}
                            options={LEVELS}
                            styles={customStyles(
                              errors.eduLevelId,
                              touched.eduLevelId
                            )}
                            value={LEVELS.find(
                              (option) => option.id === values.eduLevelId
                            )}
                            className={`custom-react-select ${
                              touched.eduLevelId
                                ? errors.eduLevelId
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            onChange={(option) => {
                              console.log(option);
                              setFieldValue(
                                "eduLevelId",
                                option ? option.id : ""
                              );
                              setFieldTouched("eduLevelId", true, false); // mark as touched but do not validate yet
                              validateField("eduLevelId"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("eduLevelId", true)}
                          />
                          {touched.eduLevelId && errors.eduLevelId && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.eduLevelId}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="monthly"
                            className="form-label">
                            Payment Type
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="monthly"
                            id="monthly"
                            placeholder="Select Coures Payment Type"
                            isSearchable={false}
                            options={PaymentOptions}
                            styles={customStyles(
                              errors.monthly,
                              touched.monthly
                            )}
                            className={`custom-react-select ${
                              touched.monthly
                                ? errors.monthly
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={
                              values.monthly
                                ? PaymentOptions[1]
                                : PaymentOptions[0]
                            }
                            onChange={(option) => {
                              setFieldValue(
                                "monthly",
                                option ? option.value : ""
                              );
                              setFieldTouched("monthly", true, false); // mark as touched but do not validate yet
                              validateField("monthly"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("monthly", true)}
                          />
                          {touched.monthly && errors.monthly && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.monthly}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="price">
                            Course Price
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="price"
                            placeholder="Enter Course Price"
                            type="text"
                            name="price"
                            className={`form-control ${
                              touched.price
                                ? errors.price
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.price && errors.price && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.price}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="requirements">
                            Course Requirements
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex flex-wrap">
                            <input
                              className={`form-control ${
                                touched.requirementsInput
                                  ? errors.requirements
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="requirementsInput"
                              type="text"
                              name="requirementsInput"
                              placeholder="Enter Requirements"
                              value={values.requirementsInput}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  if (
                                    values.requirementsInput.trim() !== "" &&
                                    !values.requirements.includes(
                                      values.requirementsInput.trim()
                                    )
                                  ) {
                                    // Only add if the input is not empty and the level is not already in the array
                                    const newLevels = [
                                      ...values.requirements,
                                      values.requirementsInput.trim(),
                                    ];
                                    setFieldValue("requirements", newLevels);
                                    setFieldValue("requirementsInput", ""); // Clear the input after adding
                                  }
                                }
                              }}
                            />
                            <ul className="d-flex gap-2 flex-wrap">
                              {values.requirements.map((level, index) => (
                                <li
                                  key={index}
                                  className="d-flex align-items-center gap-1 bg-light text-primary p-2 mt-2 rounded  cursor-pointer"
                                  onClick={() => {
                                    const newLevels =
                                      values.requirements.filter(
                                        (_, i) => i !== index
                                      );
                                    setFieldValue("requirements", newLevels);
                                  }}>
                                  <span>{level}</span>
                                  <p>
                                    <CiCircleRemove size={24} />
                                  </p>
                                </li>
                              ))}
                            </ul>
                            <div
                              className="cursor-pointer bg-light text-primary rounded text-center mt-2 ms-2 px-3 py-2 d-flex align-items-center"
                              onClick={() => {
                                if (
                                  values.requirementsInput.trim() !== "" &&
                                  !values.requirements.includes(
                                    values.requirementsInput.trim()
                                  )
                                ) {
                                  // Only add if the input is not empty and the level is not already in the array
                                  const newLevels = [
                                    ...values.requirements,
                                    values.requirementsInput.trim(),
                                  ];
                                  setFieldValue("requirements", newLevels);
                                  setFieldValue("requirementsInput", ""); // Clear the input after adding
                                }
                              }}>
                              <GoPlusCircle />
                            </div>
                            {touched.requirements && errors.requirements && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.requirements}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="outcomes">
                            Course Out Comes
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex flex-wrap">
                            <input
                              className={`form-control ${
                                touched.outcomesInput
                                  ? errors.outcomes
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="outcomesInput"
                              type="text"
                              name="outcomesInput"
                              placeholder="Enter Outcomes"
                              value={values.outcomesInput}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  if (
                                    values.outcomesInput.trim() !== "" &&
                                    !values.outcomes.includes(
                                      values.outcomesInput.trim()
                                    )
                                  ) {
                                    // Only add if the input is not empty and the level is not already in the array
                                    const newLevels = [
                                      ...values.outcomes,
                                      values.outcomesInput.trim(),
                                    ];
                                    setFieldValue("outcomes", newLevels);
                                    setFieldValue("outcomesInput", ""); // Clear the input after adding
                                  }
                                }
                              }}
                            />
                            <ul className="d-flex gap-2 flex-wrap">
                              {values.outcomes.map((level, index) => (
                                <li
                                  key={index}
                                  className="d-flex align-items-center gap-1 bg-light text-primary p-2 mt-2 rounded  cursor-pointer "
                                  onClick={() => {
                                    const newLevels = values.outcomes.filter(
                                      (_, i) => i !== index
                                    );
                                    setFieldValue("outcomes", newLevels);
                                  }}>
                                  <span>{level}</span>
                                  <p>
                                    <CiCircleRemove size={24} />
                                  </p>
                                </li>
                              ))}
                            </ul>
                            <div
                              className="cursor-pointer bg-light text-primary rounded text-center mt-2 ms-2 px-3 py-2 d-flex align-items-center"
                              onClick={() => {
                                if (
                                  values.outcomesInput.trim() !== "" &&
                                  !values.outcomes.includes(
                                    values.outcomesInput.trim()
                                  )
                                ) {
                                  // Only add if the input is not empty and the level is not already in the array
                                  const newLevels = [
                                    ...values.outcomes,
                                    values.outcomesInput.trim(),
                                  ];
                                  setFieldValue("outcomes", newLevels);
                                  setFieldValue("outcomesInput", ""); // Clear the input after adding
                                }
                              }}>
                              <GoPlusCircle />
                            </div>
                            {touched.outcomes && errors.outcomes && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.outcomes}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="description">
                            Course Description
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="description"
                            placeholder="Enter Course Description"
                            type="text"
                            name="description"
                            className={`form-control ${
                              touched.description
                                ? errors.description
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.description && errors.description && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCourses;
