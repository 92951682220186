import React, { useState } from "react";
import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader, Row } from "rsuite";
import Swal from "sweetalert2";
import { queryClient } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { COUNTRIES } from "../../../util/Countries";
import { DebounceFun } from "../../../util/DebounceFun";
import { UploadToS3 } from "../../../util/UploadToS3";
import { EditStudent } from "../../../API/Students/EditStudent";
import { CheckEmailPhoneTeacher } from "../../../API/CheckEmailPhoneTeacher";
import { GetCommunityEDucationLevels } from "../../../API/GetCommunityEDucationLevel";
import { GetSingleStudent } from "../../../API/Students/SingleSutdent/GetSingleStudent";
import { IMAGES } from "../../constant/theme";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
const debouncedCheckEmail = DebounceFun(
  (email) => CheckEmailPhoneTeacher(email, ""),
  500
);
const debouncedCheckPhone = DebounceFun(
  (phone) => CheckEmailPhoneTeacher("", phone),
  500
);

const languages = [
  { value: "Arabic", label: "Arabic" },
  { value: "English", label: "English" },
];

const customStyles = (error, touched) => ({
  control: (provided) => ({
    ...provided,
    borderColor:
      touched && error
        ? "red"
        : touched && !error
        ? "#7ed321"
        : provided.borderColor,
    "&:hover": {
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
    },
    boxShadow:
      touched && error
        ? "0 0 0 1px red"
        : touched
        ? "0 0 0 1px #7ed321"
        : "none",
  }),
});

const EditStudentForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: CommunityLevels,
    isLoading: isCommunityLevelsLoading,
    isError: isCommunityLevelsError,
  } = useQuery({
    queryKey: ["CommunityLevels"],
    queryFn: () => GetCommunityEDucationLevels(),
  });

  const {
    data: StudentData,
    isLoading: StudentLoading,
    isError: StudentError,
  } = useQuery({
    queryKey: ["StudentData"],
    queryFn: () => GetSingleStudent(id),
  });

  const { mutate } = useMutation({
    mutationFn: (data) => EditStudent(data, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllStudents"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Student Edited Successfully",
      });
      navigate("/all-students");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const LEVELS =
    !isCommunityLevelsLoading &&
    !isCommunityLevelsError &&
    CommunityLevels.map((item) => {
      return { value: item.level, label: item.level, id: item.id };
    });

  if (StudentLoading || isCommunityLevelsLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  if (StudentError)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <span className="text-primary">Something went wrong...</span>
        </div>
      </Row>
    );

  const initialValues = {
    firstName: StudentData.firstName || "",
    lastName: StudentData.lastName || "",
    password: "",
    confirmPassword: "",
    email: StudentData.email || "",
    phone: StudentData.phone || "",
    language: StudentData.language || "",
    country: StudentData.country || "",
    address: StudentData.address || "",
    city: StudentData.city || "",
    eduLevelId: StudentData.eduLevel.label || "",
    image: StudentData.image || null,
  };

  const loginSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Your username must consist of at least 3 characters ")
      .max(50, "Your username must consist of at least 3 characters ")
      .matches(
        /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
        "First Name must be alphanumeric and can include underscores and dashes"
      ),
    lastName: Yup.string()
      .min(3, "Your username must consist of at least 3 characters ")
      .max(50, "Your username must consist of at least 3 characters ")
      .matches(
        /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
        "Last Name must be alphanumeric and can include underscores and dashes"
      ),
    password: Yup.string()
      .min(5, "Your password must be at least 5 characters long")
      .max(50, "Your password must be at least 5 characters long")
      .matches(
        passwordRegex,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    email: Yup.string()
      .email("Invalid email")
      .required("Please provide an email")
      .test(
        "is-email-unique",
        "Email is already in use",
        async function (value) {
          // Only proceed with checking if the email has actually been changed
          if (!value || value === initialValues.email) return true;
          try {
            const data = await debouncedCheckEmail(value);
            if (data.emailExists) {
              return this.createError({
                path: this.path,
                message: "Email is already in use",
              });
            }
            return true;
          } catch (error) {
            console.error("Error checking email:", error);
            return this.createError({
              path: this.path,
              message: "Failed to validate email",
            });
          }
        }
      ),

    phone: Yup.number()
      .required("Please provide a phone number")
      .typeError("Phone number must be a number")
      .positive("Phone number must be a positive number")
      .test(
        "is-phone-unique",
        "Phone number is already in use",
        async function (value) {
          // Only check for uniqueness if the phone number has changed
          if (!value || value === initialValues.phone) return true;
          try {
            const data = await debouncedCheckPhone(value);
            if (data.phoneExists) {
              return this.createError({
                path: this.path,
                message: "Phone number is already in use",
              });
            }
            return true;
          } catch (error) {
            console.error("Error checking phone:", error);
            return this.createError({
              path: this.path,
              message: "Failed to validate phone number",
            });
          }
        }
      )
      .test(
        "isAtLeastTwoDigits",
        "Phone number must be at least 11 digits",
        (number) => number && number.toString().length > 9
      )
      .test(
        "isAtLeastTwoDigits",
        "Phone number must be at max 11 digits",
        (number) => number && number.toString().length <= 11
      ),
    language: Yup.string().required("Please select a language"),
    country: Yup.string().notOneOf([""], "Please select a country"),
    eduLevelId: Yup.string().required("Please select a education level"),
    // image: Yup.mixed()
    //   .test(
    //     "fileSize",
    //     "The file is too large",
    //     (value) => value && value.size <= 1024 * 1024 * 5
    //   ) // 5 MB limit
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) =>
    //       value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    //   ),
  });

  return (
    <>
      <PageTitle
        activeMenu={"All Students"}
        secondMenu={"Edit Student"}
        motherMenu={"Students"}
        userId={StudentData.id}
        goBack
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Student Info</h5>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                actions.setSubmitting(true);
                mutate(changedValues, {
                  onSuccess: () => {
                    actions.setSubmitting(false);
                    if (changedValues.image) {
                      // Check if image has changed
                      UploadToS3(
                        initialValues.image, // Assuming `data.image` is the path where the image should be saved on S3
                        changedValues.image,
                        "LMST/ADMIN/IMAGES/PROFILE/"
                      );
                    }
                  },

                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="p-5">
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={"urlLing/" + StudentData?.image}
                              alt="Profile Img"
                              onError={(e) => {
                                e.target.src = IMAGES.Avatpng1;
                              }}
                            />
                            <div
                              className="upload-link"
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update">
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="update-flie"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          {errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                          <div className="author-info">
                            <h6 className="title">
                              {StudentData?.firstName +
                                " " +
                                StudentData?.lastName}
                            </h6>
                            <span>#{id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="firstName">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="firstName"
                            placeholder="Enter First Name"
                            type="text"
                            name="firstName"
                            className={`form-control ${
                              touched.firstName
                                ? errors.firstName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.firstName && errors.firstName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lastName">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lastName"
                            placeholder="Enter Last Name"
                            type="text"
                            name="lastName"
                            className={`form-control ${
                              touched.lastName
                                ? errors.lastName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lastName && errors.lastName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Email">
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Phone_Number">
                            Phone Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Phone_Number"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            className={`form-control ${
                              touched.phone
                                ? errors.phone
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phone && errors.phone && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.password
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="Password">
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="Password"
                              placeholder="Password"
                              type={`${showPassword ? "text" : "password"}`}
                              name="password"
                              className={`form-control ${
                                touched.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.password && errors.password && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.confirmPassword
                              ? errors.confirmPassword
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="confirmPassword">
                            Confirm Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              type={`${showPassword ? "text" : "password"}`}
                              name="confirmPassword"
                              className={`form-control ${
                                touched.confirmPassword
                                  ? errors.confirmPassword
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.language
                              ? errors.language
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label className="form-label">
                            Language
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="language"
                            id="language"
                            placeholder="Select Language"
                            isSearchable={false}
                            options={languages}
                            styles={customStyles(
                              errors.language,
                              touched.language
                            )}
                            className={`custom-react-select ${
                              touched.language
                                ? errors.language
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={languages.find(
                              (option) => option.value === values.language
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "language",
                                option ? option.value : ""
                              );
                              setFieldTouched("language", true, false); // mark as touched but do not validate yet
                              validateField("language"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("language", true)}
                          />
                          {touched.language && errors.language && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="country"
                            className="form-label">
                            Country
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="country"
                            id="country"
                            placeholder="Select Country"
                            isSearchable={true}
                            options={COUNTRIES}
                            styles={customStyles(
                              errors.country,
                              touched.country
                            )}
                            className={`custom-react-select ${
                              touched.country
                                ? errors.country
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={COUNTRIES.find(
                              (option) => option.value === values.country
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "country",
                                option ? option.value : ""
                              );
                              setFieldTouched("country", true, false); // mark as touched but do not validate yet
                              validateField("country"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("country", true)}
                          />
                          {touched.country && errors.country && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.country}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="city">
                            City
                          </label>
                          <input
                            id="city"
                            placeholder="Enter city"
                            type="text"
                            name="city"
                            className={`form-control ${
                              touched.city
                                ? errors.city
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.city && errors.city && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.city}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="address">
                            Address
                          </label>
                          <input
                            id="address"
                            placeholder="Enter Address"
                            type="text"
                            name="address"
                            className={`form-control ${
                              touched.address
                                ? errors.address
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.address && errors.address && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.address}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="eduLevelId"
                            className="form-label">
                            Education Level
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="eduLevelId"
                            id="eduLevelId"
                            placeholder="Select Education Level"
                            isSearchable={false}
                            options={LEVELS}
                            styles={customStyles(
                              errors.eduLevelId,
                              touched.eduLevelId
                            )}
                            className={`custom-react-select ${
                              touched.eduLevelId
                                ? errors.eduLevelId
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={LEVELS.find(
                              (option) => option.value === values.eduLevelId
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "eduLevelId",
                                option ? option.id : ""
                              );
                              setFieldTouched("eduLevelId", true, false); // mark as touched but do not validate yet
                              validateField("eduLevelId"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("eduLevelId", true)}
                          />
                          {touched.eduLevelId && errors.eduLevelId && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.eduLevelId}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "Submit"}
                        </button>
                        <button
                          onClick={() => navigate("/all-students")}
                          type="button"
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStudentForm;
