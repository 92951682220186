import {
  S3Client,
  GetObjectCommand,
  DeleteObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

// Initialize the S3 Client with DigitalOcean Spaces credentials
const s3Client = new S3Client({
  region: "fra1", // Your space's region
  endpoint: "https://fra1.digitaloceanspaces.com", // Adjust to your endpoint
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Your access key
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Your secret key
  },
});

export async function generateSignedUrl(videoName) {
  const bucketName = "lmst-storage"; // Your bucket name
  const objectKey = `Teacher/CourseVideos/${videoName}`; // Your object key, URL-encoded

  const command = new GetObjectCommand({
    Bucket: bucketName,
    Key: objectKey,
  });

  try {
    const signedUrl = await getSignedUrl(s3Client, command, {
      expiresIn: 3600, // The URL will expire in 1 hour
    });
    return signedUrl;
  } catch (error) {
    console.error("Error generating signed URL", error);
  }
}

export async function deleteFileDigitalOcean(fileName, fileType) {
  const bucketName = "lmst-storage"; // Your bucket name
  const objectKey =
    fileType === "VIDEO"
      ? `Teacher/CourseVideos/${fileName}`
      : `Teacher/CourseFiles/${fileName}`; // Your object key

  const command = new DeleteObjectCommand({
    Bucket: bucketName,
    Key: objectKey,
  });

  try {
    const response = await s3Client.send(command);
    console.log("File deleted successfully", response);
    return response; // This will contain information about the deletion
  } catch (error) {
    console.error("Error deleting file", error);
    throw error;
  }
}
